import { Autocomplete, TableCell, TableRow, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { SyntheticEvent, memo } from "react";
import { NumericFormat } from "react-number-format";
import { currencyOf } from "utils/util";
import { IDadosCheque } from "../pagamento.model";
import { IAbstractModel } from "model/abstract.model";
import { useSnackbarCustom } from "hooks/useSnackCustom";

type LinhaDadosChequeProps = {
    index: number;
    row: IDadosCheque;
    hasError: boolean;
    bancos: Array<IAbstractModel>;
    handleChangeValor: (index: number, valor: number) => void;
    hasRowError: (row: IDadosCheque) => boolean;
    handleChangeBanco: (e: SyntheticEvent<Element, Event>, index: number, value: IAbstractModel) => void;
    handleBlurFirstRow: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, campo: string) => void;
    handleChangeAgencia: (index: number, agencia: string) => void;
    handleChangeNumeroCheque: (index: number, numeroCheque: string) => void;
    handleChangeEmitente: (index: number, emitente: string) => void;
    handleDatavencimento: (index: number, vencimentoDisplay: string) => void;
};


const LinhaDadosCheque = (props: LinhaDadosChequeProps) => {
    const { addError } = useSnackbarCustom();
    return (
        <>
            <TableRow key={props.index} id={`linha-dado-cheque-${props.index}`}>
                <TableCell align="center" >
                    <NumericFormat
                        decimalScale={2}
                        fixedDecimalScale
                        defaultValue={0}
                        allowedDecimalSeparators={[',']}
                        customInput={TextField}
                        decimalSeparator=","
                        prefix={'R$ '}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        variant="standard"
                        allowNegative={false}
                        value={currencyOf(props.row.valor)}
                        error={props.hasError}
                        onValueChange={({ floatValue }) => { props.handleChangeValor(props.index, floatValue ?? 0); }}
                        label='Valor'
                        inputProps={{
                            style: { color: props.hasError ? '#c62828' : '', },
                        }}
                        id={`valor_${props.index}`}
                    />
                </TableCell>

                <TableCell align="center">
                    <Autocomplete
                        value={props.row.banco}
                        id={`acBanco_${props.index}`}
                        options={props.bancos}
                        getOptionLabel={(option) => option.descritivo}
                        filterOptions={(options, state) => {
                            return options.filter((option) =>
                                option.descritivo.toLowerCase().includes(state.inputValue.toLowerCase())
                            );
                        }}
                        noOptionsText={'Nenhum resultado encontrado.'}
                        onChange={(event, newValue) => props.handleChangeBanco(event, props.index, newValue ?? { descritivo: '', uuid: '' })}
                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid && option.descritivo === value.descritivo}
                        renderInput={
                            (params) => <TextField {...params}
                                label="Banco"
                                variant="standard"
                                inputProps={{ ...params.inputProps, maxLength: 3 }}
                                onBlur={(event) => { if (props.index === 0) props.handleBlurFirstRow(event, 'banco'); }}
                                error={props.hasRowError(props.row)}
                            />}
                        disableClearable={true}
                    />
                </TableCell>

                <TableCell align="center">
                    <TextField
                        fullWidth={true}
                        variant="standard"
                        label='Agência'
                        value={props.row.agencia}
                        onChange={e => props.handleChangeAgencia(props.index, e.target.value)}
                        inputProps={{ maxLength: 4 }}
                        onBlur={(event) => { if (props.index === 0) props.handleBlurFirstRow(event, 'agencia'); }}
                        id={`agencia_${props.index}`}
                        error={props.hasRowError(props.row)}
                    />
                </TableCell>
                <TableCell align="center">
                    < TextField
                        fullWidth={true}
                        variant="standard"
                        label='Nº do cheque'
                        value={props.row.numeroCheque ?? ""}
                        onChange={e => props.handleChangeNumeroCheque(props.index, e.target.value)}
                        inputProps={{ maxLength: 6, minLength: 6 }}
                        onBlur={(event) => {
                            if (props.index === 0) props.handleBlurFirstRow(event, 'numeroCheque');
                            if (props.row.numeroCheque && props.row.numeroCheque?.length !== 6 && props.hasRowError(props.row)) addError({
                                id: 'erro-numerocheque-length',
                                message: 'número do cheque deve ter no minimo 6 caracteres',
                                persist: false,
                                closeable: true
                            });
                        }}
                        id={`numeroCheque_${props.index}`}
                        error={props.hasRowError(props.row)}
                    />
                </TableCell>
                <TableCell align="center">
                    < TextField
                        fullWidth={true}
                        variant="standard"
                        label='Emitente'
                        value={props.row.emitente ?? ""}
                        onChange={e => props.handleChangeEmitente(props.index, e.target.value)}
                        onBlur={(event) => { if (props.index === 0) props.handleBlurFirstRow(event, 'emitente'); }}
                        id={`numeroCheque_${props.index}`}
                        error={props.hasRowError(props.row)}
                        inputProps={{
                            maxLength: 40
                        }}
                    />
                </TableCell>
                <TableCell >
                    <DatePicker
                        slotProps={{
                            textField: {
                                id: `vencimento_${props.index}`,
                                variant: 'standard'
                            }
                        }}
                        label="Vencimento"
                        format='DD/MM/YYYY'
                        onChange={(e: any) => { props.handleDatavencimento(props.index, dayjs(e?.$d).toString() ?? new Date().toString()); }}
                        value={dayjs(props.row.vencimentoDisplay)}
                        minDate={dayjs(new Date())}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default memo(LinhaDadosCheque);
