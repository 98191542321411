import { AxiosResponse } from "axios";
import { IAbstractModel, IDescritivo } from "model/abstract.model";
import { ContaPagarValePresenteTO } from "pages/vale-presente/vale-presente.model";

export enum FormasPagamento {
    DINHEIRO = "DINHEIRO",
    CREDITO = "CARTÃO DE CRÉDITO",
    DEBITO = "CARTÃO DE DÉBITO",
    CHEQUE = "CHEQUE",
    OUTROS = "OUTROS",
    PIX = "PIX",
    NOTA = "NOTA DE CRÉDITO",
    BOLETO = "BOLETO BANCÁRIO",
    VALE_PRESENTE = "VALE PRESENTE",
    CREDIARIO = "CREDIÁRIO",
    CREDITOLOJA = "CRÉDITO DE LOJA",
    DEPOSITOBANCARIO = "DEPÓSITO BANCÁRIO",
    CARTOES = "CARTOES"
}

export type FormaPagamentoName = keyof typeof FormasPagamento;

export interface IPagamentoRow {
    name: FormaPagamentoName;
    descritivoFormaPagamento: string;
    formaPagamento: IAbstractModel;
    valor: number;
    infoAdicional?: IInfoAdicional | undefined;
    isEditavel: boolean
}

export interface INsuNumControle {
    nsu: string | null,
    numeroControle: string | null;
    credenciadora: IAbstractModel | null;
    bandeiraCartao: BandeiraCartaoTO | null;
    modoPagamento: CredenciadoraTaxaTO | null;
}

export interface IInfoAdicional extends IDescritivo {
    tipo: TipoInformacaoAdicional;
    numeroParcelas: number;
    dadosCheque?: Array<IDadosCheque>;
    observacao?: string | null;
    dadosCartao?: INsuNumControle;
    dadosGeneric?: Array<TdadosGeneric>;
    dadosValePresente?: Array<ContaPagarValePresenteTO>;
}

export enum TipoInformacaoAdicional {
    CHEQUE = 'CHEQUE',
    CARTAO = 'CARTAO',
    OUTROS = 'OUTROS',
    BOLETO = 'BOLETO',
    VALE_PRESENTE = 'VALE_PRESENTE',
    CREDIARIO = 'CREDIARIO',
    CREDITOLOJA = 'CREDITO_LOJA',
    DEPOSITOBANCARIO = "DEPOSITO_BANCARIO",
}

export type TdadosGeneric = {
    valor: number,
    vencimento: string,
    vencimentoDisplay: string;
};

export interface IDadosCheque {
    banco: IAbstractModel;
    agencia: string;
    numeroCheque: string | null;
    valor: number;
    vencimentoDisplay: string;
    vencimento: string;
    emitente: string | null;
}

export type DadosPagamento = {
    valorRecebido: number,
    infoAdicional?: IInfoAdicional;
};

export type DialogsState = {
    [key in FormaPagamentoName]: boolean;
};

export interface FinalizarFluxoParam {
    acao: AcaoFinalizarFluxo;
    request: () => Promise<AxiosResponse<any, any>>;
    toCallAfter?: (e: any) => void;
    msgFinal?: string;
    hasFullLoading?: boolean;
}

export enum AcaoFinalizarFluxo {
    SALVAR_PEDIDO,
    ATUALIZAR_PEDIDO,
    FINALIZAR_VENDA,
    FINALIZAR_VENDA_NFE,
    FINALIZAR_VENDA_OUTROS
}

export type RespostaFinalizarVenda = {
    pedido: any;
    urlDanfe?: string;
    eventoNotaFiscalSaida?: EventoNotaFiscalEmitida;
    eventoNotaFiscalEntrada?: EventoNotaFiscalEmitida;
    hasErrorEntradaSaida?: 'ENTRADA' | 'SAIDA';
    boletoURL: string;
    erroGerarBoleto: boolean;
    erroImprimirBoleto: boolean;
};

export type EventoNotaFiscalEmitida = {
    eventoDescricao: string;
    serie: string;
    numero: string;
    emissao: string;
    situacaoSefaz: string;
    chaveAcesso: string;
};


export type BandeiraCartaoTO = {
    uuidDadoCredenciadora: string,
    uuidBandeira: string,
    descritivo: string,
    numeroContrato: string | null
}

export type DadosPagamentoCartaoBandeiraTO = {
    credenciadoraUUID: string,
    bandeiras: Array<BandeiraCartaoTO>
}

export type TDadosModoPagamento = {
    credenciadoraUUID: string,
    uuidDadoCredenciadora: string,
    numeroControle: string | null
}

export type CredenciadoraTaxaTO = {
    uuidCredenciadoraTaxa: string,
    uuidBandeiraCredenciadora: string,
    modoPagamento: string
}

export type DadosPagamentoCartaoModosPagamentoTO = {
    credenciadoraUUID: string,
    bandeiraUUID: string,
    modosPagamentos: Array<CredenciadoraTaxaTO>
}

export type TDadosCartao = {
    credenciadoras: Array<IAbstractModel>,
    bandeiras: Array<DadosPagamentoCartaoBandeiraTO>,
    modosPagamento: Array<DadosPagamentoCartaoModosPagamentoTO>,
}
