import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import { TDadosPedido, TDadosPessoa } from "./comprovante-nota-fiscal-outros.model";

const ComprovanteNFOutrosService = () => {
    const path = '/flow';
    const loginService = LoginService();

    return {
        getDadosPessoa(): Promise<AxiosResponse<TDadosPessoa>> {
            return axiosRequest.get(`${path}/dadospessoa`, { headers: loginService.getHeaders() });
        },
        getDadosPedido(numeroPedido: string): Promise<AxiosResponse<TDadosPedido>> {
            return axiosRequest.get(`${path}/dadospedido/${numeroPedido}`, { headers: loginService.getHeaders() })
        }
    };
};

export default ComprovanteNFOutrosService;
