import { ColumnsTableMisc } from "utils/models";

export interface ItemRelatorioVendas {
    cliente: string,
    emissao: Date,
    modelo: string,
    numeroNotaFiscal: number,
    numeroPedido: number,
    serie: string,
    statusNotaFiscal: string,
    statusPedido: string,
    uuidNotaFiscal: string,
    chaveAcesso: string,
    uuidPedido: string,
    valorNota: number,
    valorPedido: number,
    vendedor: string,
    valorItensTroca: number,
    serieTroca: string;
    numeroNotaFiscalTroca: number,
    acao?: string;
}
export interface IdadosFooterVendas {
    quantidade: number,
    totalPedido: number,
    totalNota: number;
}

export type ColumnsRelatorioVendas = ColumnsTableMisc<ItemRelatorioVendas>;

export enum StatusPedido {
    FATURADO = "Faturado",
    ABERTO = "Aberto",
    CANCELADO = "Cancelado",
}

export enum StatusNotaFiscal {
    PENDENTE_ENVIO = "PENDENTE ENVIO",
    AUTORIZADA = "AUTORIZADA",
    CANCELADA = "CANCELADA",
    DENEGADA = "DENEGADA",
    PENDENTE = "PENDENTE",
    REJEITADA = "REJEITADA",
}

export enum CoresLegenda {
    VERDE = "VERDE",
    AMARELO = "AMARELO",
    VERMELHO = "VERMELHO",
    AZUL = 'AZUL',
    LARANJA = 'LARANJA',
    BRANCO = 'BRANCO'
}

export const Assosicacao = {
    [CoresLegenda.VERDE]: {
        rgb: "#C7F6C7",
        statusNotaFiscal: StatusNotaFiscal.AUTORIZADA
    },
    [CoresLegenda.AMARELO]: {
        rgb: "#FFFF80",
        statusNotaFiscal: StatusNotaFiscal.REJEITADA
    },
    [CoresLegenda.VERMELHO]: {
        rgb: "#FF8080",
        statusNotaFiscal: StatusNotaFiscal.CANCELADA
    },
    [CoresLegenda.AZUL]: {
        rgb: "#8080FF",
        statusNotaFiscal: StatusNotaFiscal.DENEGADA
    },
    [CoresLegenda.LARANJA]: {
        rgb: "#FFBE9F",
        statusNotaFiscal: StatusNotaFiscal.PENDENTE
    },
    [CoresLegenda.BRANCO]: {
        rgb: "#FFFFFF",
        statusNotaFiscal: StatusNotaFiscal.PENDENTE_ENVIO
    }

};

export type StatusPedidoName = keyof typeof StatusPedido;

export const getAssociacao = (item: string) => {
    return Assosicacao[item as keyof typeof Assosicacao];
};

export type TDadosFooter = {
    quantidade: number,
    totalPedido: number,
    totalNota: number;
};


export type TVenda = {
    pesquisaVenda: string,
    statusPedido: Array<StatusPedido>,
    situacaoNotaFiscal: Array<string>,
    dataInicio: string,
    dataFim: string,
    situacaoOptions: Array<StatusPedido>,
    totalizadores: Array<ItemRelatorioVendas>,
    totalizadoresError: boolean,
    dadosFooter: TDadosFooter,
    loading?: boolean;
    showTooltip?: boolean,
    showLoadingNavegandoPDV?: boolean;
    showLoadingComprovanteNaoFiscal?: boolean,
    showComprovanteFolhaA4?: boolean;
    showComprovantePreVenda?: boolean;
};

export interface IStatusProps {
    statusValues: Array<string>,
    statusOptions: Array<StatusPedido>,
    onChangeStatus: (value: StatusPedido) => void;
}

export interface ISituacaoPedidoProps {
    situacaoValues: Array<string>,
    situacaoOptions: Array<string>,
    isDisabled: boolean,
    onChangeSituacao: (value: StatusPedido) => void;
}

export type TVendasTableProps = {
    totalizadores: Array<ItemRelatorioVendas>,
    dadosFooter: IdadosFooterVendas,
    totalizadoresError: boolean,
    isLoading: boolean,
    onCancelaPedido: (uuid: string, motivo: TMotivo) => void;
    onShowImprimirDFE?: (url: string) => void;
    onNavegaPDV: (uuid: string, numeroPedido: number) => void;
    onAtualizarRelatorio: () => void;
    onShowImprimirOutros?: (uuid: string) => void;
    onShowImprimirA4?: (uuid: string) => void;
    onImprimirPreVenda?: (itemRelatorio: ItemRelatorioVendas) => void;
    onNavegaPreVenda?: (uuid: string, numeroPedido: number) => void;
    onVisualizar: (uuid: string, numeroPedido: number) => void;
};

export type TDataVendaProps = {
    title: string,
    dataValue: string,
    onChangeData: (title: string, date: string) => void,
    dataInicio: string,
    dataFim: string,
    state: string;
};


export type TMenuItemRelatorioProps = {
    itemRef: React.MutableRefObject<ItemRelatorioVendas | null>;
    onCancelar?: () => void;
    onExcluirNota?: (chaveAcesso: string) => void;
    onOpenCancelarDFe?: () => void;
    onNavigatePDV?: (uuid: string, numeroPedido: number) => void;
    onImprimirDFE?: (uuid: string) => void;
    onImprimirPedido?: (uuid: string) => void;
    onEditarVendedor?: () => void;
    onImprimirPedidoA4?: (uuid: string) => void;
    onImprimirPreVenda?: (itemRelatorio: ItemRelatorioVendas) => void;
    onNavigatePreVenda?: (uuid: string, numeroPedido: number) => void;
    onDownloadXML?: (chaveAcesso: string) => void;
    onVisualizar: (uuid: string, numeroPedido: number) => void;
};

export type TCancelaPedido = {
    onCloseCancelarModal: () => void,
    itemRef: React.MutableRefObject<ItemRelatorioVendas | null>,
    onCancelaPedido: (uuid: string, motivo: TMotivo) => void;
};

export type TMotivo = {
    uuid: string,
    id: number,
    descritivo: string;
};

export type TPedidoDialog = {
    cliente: string | null,
    entrega: number,
    numeroPedido: number,
    quantidadeTotal: number,
    status: string,
    uuid: string,
    valorTotalBruto: number,
    motivo: TMotivo,
    motivos: Array<TMotivo>,
    motivoFiltro: string;
};
